import * as React from 'react';
import { css } from '@emotion/css';
import {
  BigValue,
  BigValueColorMode,
  BigValueGraphMode,
  BigValueTextMode,
  Drawer,
  PageToolbar,
  Tab,
  TabsBar,
  Text,
  ToolbarButton,
  useStyles2,
  useTheme2
} from '@grafana/ui';
import { GrafanaTheme2 } from '@grafana/data';
import { StatusesTexts } from './Tabs/StatusesTexts';
import { useTranslation } from 'react-i18next';
import { AppContext } from 'components/SimplePanel';
import ReactMarkdown from 'react-markdown';
import DESCRIPTION_EN from '../../auto_training-doc/auto_train_description_en.md';
import DESCRIPTION_FR from '../../auto_training-doc/auto_train_description_fr.md';
import RECOMMENDATIONS_EN from '../../auto_training-doc/auto_train_recommendations_en.md';
import RECOMMENDATIONS_FR from '../../auto_training-doc/auto_train_recommendations_fr.md';
import PHASES_EN from '../../auto_training-doc/auto_train_phases_en.md';
import PHASES_FR from '../../auto_training-doc/auto_train_phases_fr.md';

import remarkGfm from 'remark-gfm';

const getStyles = (theme: GrafanaTheme2) => {
  return {
    headerContainer: css({
      paddingTop: "8px",
      display: 'flex',
      flexDirection: 'column',
    }),
    subTitle: css({
      padding: '0px 0 16px 16px',
      backgroundColor: `${theme.colors.background.secondary}`
    }),
    countsContainer: css({
      display: 'flex',
      justifyContent: 'flex-end',
      paddingTop: '8px',
      paddingLeft: '8px',
      borderRight: `1px solid ${theme.colors.border.weak}`,
      borderLeft: `1px solid ${theme.colors.border.weak}`
    })
  };
};

interface HeaderProps {
  overview: {
    totalDashboards: number;
    invalidated: number;
    ready: number;
    converging: number;
    pending: number;
    training: number;
    error: number;
    noTraining: number;
  };
}

export const HeaderContainer: React.FunctionComponent<HeaderProps> = ({ overview }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState('autoTraining');
  const [descriptionMD, setDescriptionMD] = React.useState('');
  const [recommendationsMD, setRecommendationsMD] = React.useState('');
  const [phasesMD, setPhasesMD] = React.useState('');

  const { appState } = React.useContext(AppContext);
  const { lang } = appState;
  const theme = useTheme2();
  const styles = useStyles2(getStyles);
  const { t } = useTranslation();

  const tabs = <TabsBar>
    <Tab label={`1. ${t('tabs.description')}`} active={activeTab === 'autoTraining'} onChangeTab={ev => {
      ev?.preventDefault();
      setActiveTab('autoTraining');
    }} />
    <Tab label={`2. ${t('tabs.phases')}`} active={activeTab === 'phases'} onChangeTab={ev => {
      ev?.preventDefault();
      setActiveTab('phases');
    }} />
    <Tab label={`3. ${t('tabs.recommendations')}`} active={activeTab === 'recommendations'} onChangeTab={ev => {
      ev?.preventDefault();
      setActiveTab('recommendations');
    }} />
    <Tab label={`4. ${t('tabs.sessions_statuses')}`} active={activeTab === 'statuses'} onChangeTab={ev => {
      ev?.preventDefault();
      setActiveTab('statuses');
    }} />
  </TabsBar>;

  const counts = React.useMemo(() => {
    return [
      { title: 'TOTAL SESSIONS', color: theme.visualization.getColorByName('gray'), value: overview.totalDashboards },
      { title: 'READY', color: theme.visualization.getColorByName('green'), value: overview.ready },
      { title: 'TRAINING', color: theme.visualization.getColorByName('blue'), value: overview.training },
      { title: 'CONVERGING', color: theme.visualization.getColorByName('blue'), value: overview.converging },
      { title: 'PENDING', color: theme.visualization.getColorByName('blue'), value: overview.pending },
      { title: 'NO TRAINING', color: theme.visualization.getColorByName('orange'), value: overview.noTraining },
      { title: 'INVALIDATED', color: theme.visualization.getColorByName('red'), value: overview.invalidated },
      { title: 'ERROR', color: theme.visualization.getColorByName('red'), value: overview.error },
    ];
  }, [overview, theme.visualization]);

  React.useEffect(() => {
    if (!lang) {
      return;
    }

    switch (lang) {
      case 'FR':
        setDescriptionMD(DESCRIPTION_FR);
        setRecommendationsMD(RECOMMENDATIONS_FR);
        setPhasesMD(PHASES_FR);
        break;
      case 'EN':
        setDescriptionMD(DESCRIPTION_EN);
        setRecommendationsMD(RECOMMENDATIONS_EN);
        setPhasesMD(PHASES_EN);
        break;

      default:
        break;
    }

  }, [lang]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '95%' }}>
      <div className={styles.headerContainer}>
        <PageToolbar pageIcon="play" title={t('sections.auto-training.title')?.toUpperCase()}>
          <ToolbarButton icon="document-info" onClick={() => setIsOpen(true)}>Documentation</ToolbarButton>
        </PageToolbar>
        <div className={styles.subTitle}>
          <Text element='h6' color='secondary' weight='light'>{t('header.subtitle')}</Text>
        </div>
        {isOpen && <Drawer title='Auto-training documentation' tabs={tabs} onClose={() => setIsOpen(false)}>
          {activeTab === 'autoTraining' && <div style={{ padding: '24px', paddingBottom: '64px' }}>
            <ReactMarkdown remarkPlugins={[remarkGfm]}>{descriptionMD}</ReactMarkdown>
          </div>}
          {activeTab === 'phases' && <div style={{ padding: '24px', paddingBottom: '64px' }}>
            <ReactMarkdown remarkPlugins={[remarkGfm]}>{phasesMD}</ReactMarkdown>
          </div>}
          {activeTab === 'recommendations' && <div style={{ padding: '24px', paddingBottom: '64px' }}>
            <ReactMarkdown remarkPlugins={[remarkGfm]}>{recommendationsMD}</ReactMarkdown>
          </div>}
          {activeTab === 'statuses' && <div><StatusesTexts /></div>}
        </Drawer>}
      </div>
      <div className={styles.countsContainer}>
        <div style={{ display: 'flex', maxWidth: '1105px', flexWrap: 'wrap' }}>
          {counts?.filter((count) => count.value !== 0)
            ?.map((count => <DisplayValueCount value={count.value} title={count.title} color={count.color} key={count.title} />))}
        </div>
      </div>
    </div>
  );
};

interface DisplayValueProps {
  value: number;
  title: string;
  color: string;
}
const DisplayValueCount: React.FunctionComponent<DisplayValueProps> = ({ value, title, color }) => {
  const theme = useTheme2();

  return (
    <div style={{ marginRight: '8px', marginBottom: '8px' }}>
      <BigValue
        theme={theme}
        width={130}
        height={75}
        colorMode={BigValueColorMode.Background}
        graphMode={BigValueGraphMode.None}
        textMode={BigValueTextMode.ValueAndName}
        disableWideLayout={false}
        value={{
          text: `${value}`,
          numeric: value,
          color: color,
          title: title
        }}
      />
    </div>
  );
};
