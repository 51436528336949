import { DataSourceApi } from '@grafana/data';
import { getBackendSrv } from '@grafana/runtime';
import { NotificationError } from 'components/Shared';
import { API_QUERY_DS, LORAWAN_DL, SETTINGS_OFFSET, URL_ISO_JSON, URL_POST_DATASOURCE, URL_POST_DATASOURCE_UID } from 'utils/constants';
import { decodeBasicSettings } from 'utils/settings';

/**
 * Get request to mysql
 * @param datasourceUid 
 * @param query 
 */

export const mysqlRequest = async (datasourceUid: string, query: string): Promise<any[]> => {
  if (!datasourceUid || !query) {
    return [];
  }

  const mysqlResults = await getBackendSrv()
    .post(API_QUERY_DS, {
      queries: [
        {
          datasource: {
            type: 'mysql',
            uid: datasourceUid,
          },
          format: 'table',
          rawSql: query,
          refId: 'tempvar',
        },
      ],
    })
    .catch(err => {
      NotificationError(err);
    });

  const results = mysqlResults?.results?.tempvar?.frames;
  if (results?.length) {
    const resultsArrays = results[0].data.values;

    if (!resultsArrays?.length) {
      return [];
    }

    const finalresult = resultsArrays[0].map(() => new Array(resultsArrays.length));
    for (const [indexArray, array] of resultsArrays.entries()) {
      for (const [indexValue, value] of array.entries()) {
        finalresult[indexValue][indexArray] = value;
      }
    }
    return finalresult;
  } else {
    return [];
  }
};

/** 
 * Get request to influx
 * @return queryResult.results[0].series 
 */

export const influxRequest = async (datasource: { name: string; id: number; }, query: string) => {
  const urlQuery = `${URL_POST_DATASOURCE}${datasource?.id}/query?db=${datasource.name}&q=${query}`;
  const queryResult = await getBackendSrv()
    .get(urlQuery)
    .catch((err) => {
      NotificationError(err);
    });
  return queryResult?.results[0]?.series ? queryResult.results[0]?.series : [];
};

/** Send device to lorawan endpoint */

export const triggerSettingsExpedition = async (
  client: string,
  gw: string,
  device: string,
  datasource: DataSourceApi,
) => {
  const dlSpecs = {
    client: client,
    gw: gw,
    device: device,
  };
  const url = `${URL_POST_DATASOURCE_UID}${datasource.uid}${LORAWAN_DL}`;

  await getBackendSrv()
    .post(url, dlSpecs, {
      responseType: 'text',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .catch((err: any) => {
      console.log(err);
      NotificationError(err);
    });
};


export const getIsoClassifications = async () => {
  const results = await getBackendSrv()
    .get(URL_ISO_JSON)
    .catch((e) => {
      NotificationError({ message: 'Error while getting ISO Classifications JSON' });
    });
  return results;
};

export const fetchPrivateSettings = async (query: string, dsInflux: { id: number, name: string; }) => {
  // Request private settings to get RPM
  const settingsResult = await influxRequest({ name: dsInflux.name, id: dsInflux.id }, query)
    .catch((err) => {
      NotificationError(err);
      return;
    });

  let rpmMinValue = '';
  let rpmMaxValue = '';
  let rawValue = '';
  let settingReceptionDate = '';

  if (settingsResult?.length && settingsResult[0]?.values) {
    settingReceptionDate = settingsResult[0].values[0][0];
    rawValue = settingsResult[0].values[0][1];
    const basicSettingsValue = rawValue.slice(SETTINGS_OFFSET, rawValue.length + 1); // settings purged of sensor type & orientation values
    const decodedSettings = decodeBasicSettings(basicSettingsValue);
    // Update settings
    rpmMinValue = decodedSettings.rpm_min?.toString();
    rpmMaxValue = decodedSettings.rpm_max?.toString();
  }
  return { rpmMin: rpmMinValue, rpmMax: rpmMaxValue, rawValue, settingReceptionDate };
}

