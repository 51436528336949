import * as React from 'react';
import {
  Badge,
  Field,
  Text,
  useStyles2,
} from '@grafana/ui';
import { AppContext } from 'components/SimplePanel';
import { CustomCard } from 'components/Shared/CustomCard';
import { getAiTrainingPerformed, getIdTrainingSetQuery, getTrainingZones } from 'services/getQueries';
import { mysqlRequest } from 'services/datasourcesRequest';
import { SESSION_TYPE, TRAINING_STATUS, TableData } from 'types/state';
import { getSectionsStyles } from './sectionsStyles';
import { getFormattedIds, getIconNameStatus } from 'utils/helpers';
import { dateTimeFormatISO } from '@grafana/data';
import { NotificationWarning, NotificationError } from 'components/Shared/Notification';
import { useTranslation } from 'react-i18next';

/** Session card */

interface SessionProps {
  session: TableData['training'];
  macAddress: TableData['macAddress'];
}

interface TrainingZonesProps {
  startDate: string;
  endDate: string;
};

export const Session: React.FunctionComponent<SessionProps> = ({ session, macAddress }) => {
  const { appState } = React.useContext(AppContext);
  const { datasources } = appState;

  const { performedDate } = session;
  const [trainingZones, setTrainingZones] = React.useState([] as TrainingZonesProps[]);
  const [convergingEndDate, setConvergingEndDate] = React.useState('');
  const [autoTrainingStatus, setAutotrainingStatus] = React.useState('');

  const styles = useStyles2(getSectionsStyles);
  const { t } = useTranslation();

  React.useEffect(() => {
    // Fetch training zones
    const fetchTrainingZones = async () => {
      let trainingZonesFetched: Array<{
        startDate: string;
        endDate: string;
      }> = [];
      // Get training zones
      if (session?.sessionId !== undefined) {
        const queryAiTrainRel = getIdTrainingSetQuery(macAddress, session?.sessionId);
        const idTrainingZonesResult = await mysqlRequest(datasources.mysql.uid, queryAiTrainRel)
          .catch((err: any) => {
            console.log(err);
            NotificationError(err);
          });

        if (!idTrainingZonesResult?.length) {
          NotificationWarning(t('errors.training_zones_not_found'));
          return;
        }

        let trainingZonesId = getFormattedIds(idTrainingZonesResult);
        const trainingZonesQuery = getTrainingZones(macAddress, trainingZonesId);
        const trainingZonesResult = await mysqlRequest(datasources.mysql.uid, trainingZonesQuery)
          .catch(err => {
            NotificationError(err);
          });

        if (trainingZonesResult?.length) {
          for (const session of trainingZonesResult) {
            trainingZonesFetched.push({ startDate: dateTimeFormatISO(dateTimeFormatISO(session[1])), endDate: dateTimeFormatISO(session[2]) });
          }
        }
      }

      if (trainingZonesFetched?.length) {
        setTrainingZones(trainingZonesFetched);
      }
    };

    // Fetch ai_training performed date
    const fetchAutoTrainInfo = async () => {
      if (session?.sessionId !== undefined) {
        const aiTrainingQuery = getAiTrainingPerformed(session.sessionId);
        const aiTrainingInfosResult = await mysqlRequest(datasources.mysql.uid, aiTrainingQuery)
          .catch((err) => {
            console.log('Can not get ai training infos');
            NotificationError(err);
          });
        if (aiTrainingInfosResult?.length) {
          setAutotrainingStatus(aiTrainingInfosResult[0][1]);
          setConvergingEndDate(aiTrainingInfosResult[0][0]);
        }
      }
    };

    if (datasources.mysql.uid) {
      fetchTrainingZones();
      // Fetch auto training infos only if type AUTO_TRAIN and
      // status TRAINING, PENDING, READY
      if (session.type === SESSION_TYPE.AutoTrain &&
        (session.status === TRAINING_STATUS.Training
          || session.status === TRAINING_STATUS.Pending
          || session.status === TRAINING_STATUS.Ready
        )) {
        fetchAutoTrainInfo();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datasources.mysql.uid]);

  const getDescriptionAutoTrainStatus = (status: string) => {
    let description = '';
    switch (status) {
      case "OK":
        description = t('statuses.auto-training.ok');
        break;
      case "WARNING_IND":
        description = t('statuses.auto-training.warn_ind');
        break;
      case "WARNING_CONV":
        description = t('statuses.auto-training.warn_conv');
        break;
      default:
        break;
    }
    return description;
  };

  return (
    <div className={styles.container}>
      <CustomCard cardTitle='Session' canEdit={false}>
        {/* Performed date */}
        {performedDate && <Field label='' description={t('sections.session.performed_date')}>
          <Text>{dateTimeFormatISO(performedDate)}</Text>
        </Field>}
        {/* Converging end date */}
        {convergingEndDate &&
          <Field label='' description={t('sections.session.converging_end_date')}>
            <Text>{dateTimeFormatISO(convergingEndDate)}</Text>
          </Field>}
        {/* Auto train status */}
        {autoTrainingStatus &&
          <Field label='' description={t('sections.session.auto_training_status')}>
            <Badge
              color={getIconNameStatus(autoTrainingStatus).color}
              icon={getIconNameStatus(autoTrainingStatus).iconName}
              text={autoTrainingStatus}
              tooltip={getDescriptionAutoTrainStatus(autoTrainingStatus)} />
          </Field>}
        {/* Training zones */}
        {trainingZones.length !== 0 && <>
          <Text element='h6' weight='bold'>{t('sections.session.training_zones')}</Text>
          <div style={{ maxHeight: '110px', overflowY: 'auto' }}>
            {trainingZones?.map(zone => (<div key={zone.startDate}>
              <Field label='' description={t('sections.session.start_date')}><Text>{zone.startDate}</Text></Field>
              <Field label='' description={t('sections.session.end_date')}><Text>{zone.endDate}</Text></Field>
            </div>
            ))}
          </div>
        </>
        }
      </CustomCard>
    </div>
  );
};
