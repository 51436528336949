import React, { useState } from "react";
import { Pagination, Select, useStyles2 } from "@grafana/ui";
import { cx } from "@emotion/css";
import {
  ColumnDef,
  ColumnFiltersState,
  ExpandedState,
  PaginationState,
  Row,
  Table as TableI,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable
} from '@tanstack/react-table';
import { Filters } from "./Filters";
import { NoTraining } from "components/TrainingStatusCard/NoTraining";
import { Training } from "components/TrainingStatusCard/Training";
import { Ready } from "components/TrainingStatusCard/Ready";
import { getPageSizeOptions } from "utils/helpers";
import { getStyles } from "./tableStyles";

import { TRAINING_STATUS, TableData } from "types/state";
import { Converging } from "components/TrainingStatusCard/Converging";

interface TableProps {
  data: TableData[];
  columns: Array<ColumnDef<TableData>>;
  getRowCanExpand: (row: Row<TableData>) => boolean;
}

const Table: React.FunctionComponent<TableProps> = ({ columns, data, getRowCanExpand }) => {
  const styles = useStyles2(getStyles);

  // Table instance
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [expanded, setExpanded] = React.useState<ExpandedState>({});
  const [pagination, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const table = useReactTable({
    data,
    columns,
    getRowCanExpand,
    filterFns: {},
    autoResetPageIndex: false,
    state: {
      columnFilters,
      expanded,
      pagination
    },
    onExpandedChange: setExpanded,
    onColumnFiltersChange: setColumnFilters,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getFacetedRowModel: getFacetedRowModel(), // client-side faceting
    getFacetedUniqueValues: getFacetedUniqueValues(), // generate unique values for select filter/autocomplete
    getPaginationRowModel: getPaginationRowModel(),
  });

  React.useEffect(() => {
    if (pagination.pageIndex >= table.getPageCount()) {
      setPagination({ ...pagination, pageIndex: 0 }); // Reset to first page if current page is out of range
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, table.getState().columnFilters]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <PaginationComponent table={table} />
      <div className={styles.container}>
        <table className={styles.table}>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    className={styles.header}
                    style={{
                      borderBottom: "2px solid #ddd",
                      padding: "0.5rem",
                    }}
                  >
                    <>
                      <div
                        {...{
                          className: header.column.getCanSort()
                            ? 'cursor-pointer select-none'
                            : '',
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {{
                          asc: ' 🔼',
                          desc: ' 🔽',
                        }[header.column.getIsSorted() as string] ?? null}
                      </div>
                      {header.column.getCanFilter() ? (
                        <div>
                          <Filters column={header.column} table={table} data={data} />
                        </div>
                      ) : null}
                    </>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row, i) => {
              return (
                <>
                  <tr key={row.id} className={cx(styles.row, row.getIsExpanded() && styles.expandedRow)}>
                    {row.getVisibleCells().map(cell => {
                      return <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>;
                    })}

                  </tr>
                  {/* Expanded row */}
                  {row.getIsExpanded() && (
                    <tr className={styles.expandedContentRow}>
                      <td colSpan={row.getVisibleCells().length}>
                        {/* Display NO TRAINING, INVALIDATED and ERROR views */}
                        {(row.getValue('trainingStatus') === TRAINING_STATUS.NoTraining
                          || row.getValue('trainingStatus') === TRAINING_STATUS.Invalidated
                          || (row.getValue('trainingStatus') as string).includes('ERR_'))
                          && <NoTraining rowOriginal={row.original} />}
                        {/* Display TRAINING, PENDING views */}
                        {((row.getValue('trainingStatus') === TRAINING_STATUS.Training) || (row.getValue('trainingStatus') === TRAINING_STATUS.Pending))
                          && <Training rowOriginal={row.original} />}
                        {/* Display READY view */}
                        {row.getValue('trainingStatus') === TRAINING_STATUS.Ready
                          && <Ready rowOriginal={row.original} />}
                        {/* Display CONVERGING view */}
                        {row.getValue('trainingStatus') === TRAINING_STATUS.Converging
                          && <Converging rowOriginal={row.original} />}
                      </td>
                    </tr>
                  )}
                </>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>

  );
};

export const TrainingTable: React.FunctionComponent<TableProps> = ({ columns, data }) => {
  return (
    <div style={{ padding: "1rem" }}>
      <Table columns={columns} data={data} getRowCanExpand={() => true} />
    </div>
  );
};

interface PaginationComponentProps {
  table: TableI<TableData>;
}
const PaginationComponent: React.FunctionComponent<PaginationComponentProps> = ({ table }) => {
  return (
    <React.Fragment>
      {/* Pagination */}
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
        <div>
          <Pagination
            currentPage={table.getState().pagination.pageIndex + 1}
            numberOfPages={table.getPageCount()}
            onNavigate={(toPage) => table.setPageIndex(toPage - 1)}
          />
        </div>
        <div style={{ display: 'flex', alignItems: 'center', marginLeft: '16px', width: '120px' }}>
          <div style={{ marginRight: '8px' }}>Show</div>
          <Select
            value={table.getState().pagination.pageSize}
            options={getPageSizeOptions()}
            onChange={e => {
              table.setPageSize(e.value);
            }}
          />
        </div>
      </div>
    </React.Fragment>
  );
}


